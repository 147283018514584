import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'

import { SEOMetaTags, RecaptureForm } from 'blog-components'

import * as Constants from '../../Constants'

import * as messages from '../../messages.json'

class ContactUs extends React.Component {
  successComponent() {
    return (
      <div className="main-container">
        <div className="row justify-content-center no-gutters text-center">
          <div className="col-sm-8">
            <h1 className="display-4 py-5">
              {messages.pages.contactus.components.success.heading}
            </h1>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const site = get(this, 'props.data.site')

    return (
      <Layout>
        <SEOMetaTags
          path="/contactus/"
          site={site}
          title={messages.pages.contactus.metaData.title}
          description={messages.pages.contactus.metaData.description}
          imageUrl={site.siteMetadata.siteUrl + '/main-logo.png'}
        />
        <div className="row justify-content-center no-gutters px-3">
          <div className="col-sm-8">
            <h1 className="display-4 text-center py-5 ">
              {messages.pages.contactus.components.heading}
            </h1>
            <p className="lead">
              {messages.pages.contactus.components.paragraph_1}
            </p>
          </div>
        </div>
        <div className="row justify-content-center no-gutters px-3">
          <div className="col-sm-8">
            <RecaptureForm
              successComponent={this.successComponent}
              submitButtonLabel={
                messages.pages.contactus.components.form.submit.label
              }
              formType={Constants.FORM_TYPE_CONTACTUS}
            >
              <div className="form-group">
                <label htmlFor="name">
                  {messages.pages.contactus.components.form.name.label}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder={
                    messages.pages.contactus.components.form.name.placeholder
                  }
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  {messages.pages.contactus.components.form.email.label}
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder={
                    messages.pages.contactus.components.form.email.placeholder
                  }
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">
                  {messages.pages.contactus.components.form.message.label}
                </label>
                <textarea
                  rows="4"
                  className="form-control"
                  id="message"
                  name="message"
                  placeholder={
                    messages.pages.contactus.components.form.message.placeholder
                  }
                  required
                />
              </div>
            </RecaptureForm>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ContactUs
export const query = graphql`
  query ContactUsQuery {
    site {
      ...SiteInformation
    }
  }
`
